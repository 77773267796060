import { createApp } from 'vue';
import App from './App.vue';

// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: 'var(--primary-color)',
    secondary: '#424242',
    accent: '#82B1FF',
    info: '#2196F3',
    success: '#0fb47a',
    warning: '#FFC107',
    background: '#dedede',
    surface: '#dedede',
    'on-surface': '#2e413d;'
  }
};

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    }
  }
});

createApp(App).use(vuetify).mount('#app');
