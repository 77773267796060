<template>
  <v-dialog v-model="dialog" max-width="800" class="cont">
    <v-card>
      <v-card-title class="modal-top d-flex justify-space-between align-center">
        <div class="title">¡Hola!</div>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
          <p class="modal-body">
            ¡Bienvenidos a la página dedicada a las cositas de Tales, siempre y cuando el nombre no cambie! 😅
            <br /><br />
            Aquí encontrarás una lista detallada de todo lo que necesitamos para dar la bienvenida a nuestro pequeño.
            Hemos incluido enlaces a artículos disponibles en internet, pero estamos completamente abiertos a recibir
            artículos similares o de segunda mano.
            <br /><br />
            ¡Desde ya, muchísimas gracias! ¡Estamos emocionados por este nuevo capítulo de nuestras vidas!
          </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: true
    };
  },
  methods: {
    closeDialog () {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>

</style>
