<template>
      <v-row  @click="openTalesModal()" dense>
        <div class="navbar">
          <v-img :src="require(`../assets/cig.png`)" class="" height="60px" contain></v-img>
          <span class="title">TALES</span>
          <v-img :src="require(`../assets/cig2.png`)" class="" height="60px" contain></v-img>
        </div>
      </v-row>
    <v-container  class="mx-auto" max-width="1000">
    <InitialModal/>
      <v-row dense>
        <v-col>
          <v-range-slider v-model="priceRange" label="Precio" min="0" :max="maxPrice" step="1" class="price-range-slider"
            thumb-label="always" hide-details>
            <template v-slot:thumb-label="item">
              <div class="thumb-label-content">
                <span>{{ item.modelValue }} €</span>
              </div>
            </template>
          </v-range-slider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-for="card in filteredCards" :key="card.title" cols="12" xs="12" sm="6" md="4" class="px-2 py-2">
          <v-card @click="openModal(card)" :class="{ 'greyed-out': !card.available }">
            <v-img :src="card.src" gradient="to bottom, rgba(0,0,0,0.05), rgba(0,0,0,.2)" height="350px"
              cover>
            </v-img>

            <v-card-actions>
              <v-card-title class="card-title">{{ card.title }}</v-card-title>
              <v-icon v-if="card.type=='segunda_mano'" icon="mdi-recycle" />
              <span v-else-if="(card.available && card.id !== 'especial')">{{`${card.price} €` }}</span>
            </v-card-actions>
            <v-img v-if="!card.available" :src="require(`../assets/adjudicado.png`)" class="img-cover" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="350px"
              contain>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <CardModal v-model:modalVisible="modalVisible" :selectedCard="selectedCard" @buyItem="buyItem" />
    <TalesModal v-model:modalVisible="modalTalesVisible" />
</template>

<script>
import axios from 'axios';

import CardModal from './CardModal.vue';
import TalesModal from './TalesModal.vue';
import InitialModal from './InitialModal.vue';

export default {
  data: () => ({
    cards: {},
    modalVisible: false,
    modalTalesVisible: false,
    selectedCard: null,
    maxPrice: 1000,
    lowerValue: 0,
    higherValue: 1000
  }),
  methods: {
    async fetchCards () {
      try {
        const response = await axios.get('https://the-baby-list-default-rtdb.europe-west1.firebasedatabase.app/items.json');
        this.cards = response.data;
        this.maxPrice = Object.values(this.cards).reduce((acc, curr) => Math.max(acc, curr.price), 0);
        const special =
        {
          id: 'especial',
          description: '¿Tienes algo en mente que no esté en la lista? Puede ser algo nuevo o de segunda mano. Envía un mensajito a mis papis, y ellos se encargarán de ponerse en contacto contigo si necesitan más detalles.',
          available: true,
          src: require('../assets/baby-happy.png'),
          title: 'Otros'
        };
        this.cards.especial = special;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    openModal (card) {
      if (card.available) {
        this.selectedCard = card;
        this.modalVisible = true;
      }
    },
    openTalesModal (card) {
      this.modalTalesVisible = true;
    },
    buyItem (card) {
      card.available = false;
    }
  },
  components: {
    CardModal,
    TalesModal,
    InitialModal
  },
  computed: {
    priceRange: {
      get () {
        return [this.lowerValue, this.higherValue];
      },
      set (value) {
        this.lowerValue = value[0];
        this.higherValue = value[1];
      }
    },
    filteredCards () {
      // Filter cards by price range
      return Object.entries(this.cards)
        .filter(([key, value]) => (value.price >= this.lowerValue && value.price <= this.higherValue) || value.id === 'especial')
        .sort(([key1, value1], [key2, value2]) => {
          if (value1.available && !value2.available) {
            return -1; // Place available cards before non-available cards
          } else if (!value1.available && value2.available) {
            return 1; // Place non-available cards after available cards
          } else {
            if (key1 === 'especial') return 1; // 'especial' card after all available
            return 0; // Maintain current order if both available or both non-available
          }
        })
        .map(([key, value]) => value);
    }
  },
  async mounted () {
    await this.fetchCards();
  }
};
</script>

<style scoped>
.greyed-out {
  cursor: not-allowed;
}
.thumb-label-content {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.price-range-slider {
  padding: 10px 10px;
}
.v-slider.v-slider.v-slider {
  background-color: white;
  box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 3px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  margin: 0 0 20px;
  padding: 10px 20px 10px 10px;
  border-radius: 5px;
}
.v-card {
  position: relative;
}
.img-cover{
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgb(255, 255, 255, 0.6);
}
.v-card-actions{
  position: absolute;
  bottom: 0;
  background-color: rgb(255, 255, 255, 0.85);
  width: 100%;
  justify-content: space-between;
}
.v-card-title {
  padding: 0;
}
.navbar{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  background-color: var(--secondary-color);
  padding: 10px 0 5px;
  align-items: center;
  box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 1px 1px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 3px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  cursor: pointer
}
.title {
  font-size: 40px;
}
</style>
