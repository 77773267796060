<template>
  <LayoutCmp/>
</template>

<script>
import LayoutCmp from './components/LayoutCmp.vue';

export default {
  name: 'App',
  components: {
    LayoutCmp
  }
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&family=Kranky&family=Schoolbell&family=Short+Stack&display=swap');

:root {
  --primary-color: #2e413d;
  --secondary-color:#a4d7c5;
  --third-color: #dedede;
  font-family: 'Schoolbell', cursive;
}

.title {
  font-family: 'Kranky', serif;
}
#app {
  font-family: 'Schoolbell', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0px 0  60px;
  background-color: rgba(164, 215, 197, 0.4);

}
body {
  color: var(--primary-color);
  background-color: var(--third-color);
}
.v-card--variant-elevated, .v-card--variant-flat{
  color: var(--primary-color);
}

.modal-top {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.modal-top .v-btn{
  box-shadow: none;
  border: none;
}
.v-btn.no-btn-effect {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
}
input {
  font-size: 14px;
}
</style>
