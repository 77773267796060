<template>
  <v-dialog v-model="localModalVisible" @click:outside="closeModal" max-width="600">
      <v-card class="card" >
        <v-card-title class="modal-top d-flex justify-space-between align-center">
          <div>
            <v-btn v-if="(selectedCard.id!=='especial' && selectedCard.type !== 'segunda_mano')" v-bind:href="selectedCard.link" append-icon="mdi-link-variant" target="_blank">
              <span class="text-decoration-underline title">{{ selectedCard.title }}</span>
            </v-btn>
            <span v-else class="title">{{ selectedCard.title }}</span>
          </div>

          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>

          </v-btn>
        </v-card-title>
        <div class="card-main">
          <div  class="background-img" :style="bannerBgImage(selectedCard.src)"></div>
          <div v-if="mode==='especial'">
            <v-card-text>
              <div class="pb-3">
                <p  class="description">{{ selectedCard.description }}</p>
              </div>
              <v-text-field
                v-model="buyerName"
                label="Nombre"
                :rules="rules"
                hide-details="auto"
              ></v-text-field>
              <v-textarea
                class="pt-3"
                v-model="buyerMessage"
                label="Mensaje"
                :rules="rules"
              ></v-textarea>
            </v-card-text>

            <v-card-actions class="buy-options">
              <v-btn prepend-icon="mdi-send-outline" @click="handleSpecialCases" :disabled="!(buyerName && buyerMessage)">
                <span>Envirar</span>
              </v-btn>
            </v-card-actions>
          </div>
          <div v-if="mode=='info'" >
            <v-card-text>
              <div class="info-description, pb-3">
                <p>{{ selectedCard.description }}</p>
              </div>
              <p><a :href="selectedCard.link" target="_blank">Aquí tienes el enlace</a> del artículo específico que habíamos mirado. Su precio aproximado es de {{ selectedCard.price }}€, pero no somos expertos en cosas de bebé por ahora. Así que, si encuentras una mejor opción, siéntete libre de comprarla o mándanos el enlace para que lo compremos. ¡Gracias!</p>
            </v-card-text>
            <v-card-actions class="info-options">
              <div v-for="[key, content] in Object.entries(this.buyOptions)" :key="key">
                <v-btn :prepend-icon=content.icon class="mx-1 my-1" variant="tonal" @click="goToBuy({key, content}, $event)">{{content.name}}</v-btn>
              </div>
            </v-card-actions>
          </div>

          <div v-if="mode=='buy'">
            <v-card-text>
              <div class="info-description, pb-3">
                <p  class="description">{{ optionSelected.key == "comprar" ? optionSelected.content.description[selectedCard.destination] : optionSelected.content.description }}</p>
                <div v-if="optionSelected.key == 'comprar'" class="info-address mt-3">
                  <p v-for="(item, index) in optionSelected.content.address[selectedCard.destination]" :key="index">{{item}}</p>
                </div>
                <p class="pt-5 description">
                  {{ optionSelected.key==="segunda_mano" || optionSelected.key==="otra" ? `Cuéntamelo todo en un mensajito y así nos pondremos de acuerdo con el tema logística.\n\n¡Gracias!` : `Ahora viene mi parte favorita, que nos dejes un mensajito.` }}
                </p>
              </div>

              <v-text-field
                v-model="buyerName"
                label="Nombre"
                :rules="rules"
                hide-details="auto"
              ></v-text-field>
              <v-textarea
                class="pt-3"
                v-model="buyerMessage"
                label="Mensaje"
                :rules="rules"
              ></v-textarea>
              <p class="info-description, pb-3, font-weight-bold">
                En el siguiente paso, bloquearemos el artículo para que nadie más lo compre.
              </p>
            </v-card-text>

            <v-card-actions class="buy-options">
              <v-btn prepend-icon="mdi-keyboard-return" @click="goToInfo">
                <span>Volver</span>
              </v-btn>
              <v-btn prepend-icon="mdi-cart-outline" @click="buyItem" :disabled="!(buyerName && buyerMessage)">
                <span>Yo me encargo</span>
              </v-btn>
            </v-card-actions>
          </div>
          <div v-if="mode=='thanks'">
            <v-card-text class="thanks">
              <v-img :src="require(`../assets/thanks.png`)" class="mb-3" height="50px" contain></v-img>
              <p class="font-weight-bold">¡Oído cocina!</p>
              <p class="mt-1">¡Muchísimas gracias, {{ buyerName }}! Eres mi persona favorita en el mundo y todavía no te conozco en persona. Seguramente, mis papis se pondrán en contacto contigo porque querrán agradecértelo también. ¡Nos vemos pronto!</p>

            </v-card-text>
            </div>
          <div v-if="mode=='error'">
            <v-card-text class="thanks">
              <v-img :src="require(`../assets/error.png`)" class="mb-3" height="50px" contain></v-img>
              <p class="font-weight-bold">Ops!</p>
              <p class="mt-1">{{ errorMessage }}</p>
            </v-card-text>
            </div>
        </div>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    modalVisible: Boolean,
    selectedCard: Object
  },
  data () {
    return {
      buyOptions: undefined,
      localModalVisible: this.modalVisible,
      mode: 'info', // info, buy, thanks, error, especial
      optionSelected: undefined,
      buyerName: '',
      buyerMessage: '',
      errorMessage: '',
      rules: [
        value => !!value || 'Required.'
      ]
    };
  },
  watch: {
    modalVisible (newValue) {
      this.localModalVisible = newValue;
    },
    selectedCard (newCard) {
      this.mode = newCard.id === 'especial' || newCard.type === 'segunda_mano' ? 'especial' : 'info';

      this.buyOptions = {
        comprar: {
          name: 'Comprar por mi cuenta',
          description: {
            es: '¡Genial, muchas gracias!\n\nVamos a recoger este regalo cuando logre conseguir mi pasaporte y vayamos a ver a los abuelos.\n\nSi queréis enviarle el regalo al abuelo Manolo, su dirección es:',
            ri: '¡Genial, muchas gracias!\n\nVamos a recoger este regalo en casa de los abuelos de Riotinto.\n\nSi queréis enviarlo allí la dirección es:',
            uk: '¡Genial, muchas gracias!\n\nEl tema de la logística en este caso está un poco más complicado. Sería perfecto si pudieras enviarlo a mi casa en el Reino Unido.\n\nMi dirección es:'
          },
          address: {
            es: ['C/ Padre Luque 7, esc 1, 3A', '41014, Sevilla'],
            ri: ['C/ Sevilla 27', '21660, Minas de Riotinto, Huelva'],
            uk: ['27A East Street', 'Leighton Buzard, LU71HX', 'United Kingdom']
          },
          icon: 'mdi-shopping'
        },
        bizum: {
          name: 'Bizum',
          description: `Hola,\n\n¿Podría resultarte más fácil hacerle un Bizum directamente a mami? Su número es 619509389. El precio de este artículo ronda los ${newCard?.price}€.\n\nMis papis siempre intentan mantener los precios al día, pero si hay alguna variación, me han dicho que si es más, no te preocupes, ellos cubrirán la diferencia. Y si es menos, ¡genial! Lo usarán para comprarme algo que necesite. ¡Gracias!\n\n¿Podrías usar "${newCard?.id}" como concepto para que mis papis sepan qué necesitan comprar?`,
          icon: 'mdi-bank'
        },
        segunda_mano: {
          name: 'Segunda Mano',
          description: '¡Genial!\n\nEn esta casa somos muy hippies, así que estamos súper a favor de la reutilización. ¿De quién he heredado?',
          icon: 'mdi-recycle'
        },
        otra: {
          name: 'Otra',
          description: '¿Tienes alguna otra idea en mente o prefieres mandarles directamente libras a mis papis? \n\nEscríbenos un mensajito explicándonosla, y nos pondremos en contacto contigo.\n\n¡Muchísimas gracias!',
          icon: 'mdi-comment'
        }
      };
    }
  },
  methods: {
    bannerBgImage (image) {
      return `background-image: url(${image})`;
    },
    goToInfo () {
      this.optionSelected = undefined;
      this.mode = 'info';
    },
    goToBuy (option) {
      this.optionSelected = option;
      this.mode = 'buy';
    },
    async isItemAvailable () {
      const url = `https://the-baby-list-default-rtdb.europe-west1.firebasedatabase.app/items/${this.selectedCard.id}/available.json`;
      const response = await axios.get(url);

      return response.data;
    },
    async updateSpecial () {
      const url = `https://the-baby-list-default-rtdb.europe-west1.firebasedatabase.app/mensajitos/${this.buyerName}-${Date.now()}.json`;

      const buyer = {
        name: this.buyerName,
        messagae: this.buyerMessage
      };

      // Put requests updates current value
      await axios.put(url, buyer);
    },
    async updateData () {
      const url = `https://the-baby-list-default-rtdb.europe-west1.firebasedatabase.app/items/${this.selectedCard.id}.json`;

      const currentCard = this.selectedCard;
      currentCard.available = false;
      currentCard.buyer = this.buyerName;
      currentCard.buyerMessage = this.buyerMessage;
      currentCard.mode = this.optionSelected ? this.optionSelected.key : this.selectedCard.type;

      // Put requests updates current value
      await axios.put(url, currentCard);
    },
    closeModal () {
      this.$emit('update:modalVisible', false);
      this.optionSelected = undefined;
      this.localModalVisible = false;
      this.mode = this.selectedCard.id === 'especial' ? 'especial' : 'info';
    },
    async notify () {
      const text = `Item: ${this.selectedCard.id}${this.optionSelected ? '\nMethod: ' + this.optionSelected.key : ''}\nName: ${this.buyerName}\nMessage: ${this.buyerMessage}`;
      const url = 'https://api.telegram.org/bot6601380552:AAGmawPILyPhbYPyP8hEdLltZCAwy3ovkys/sendMessage';
      const payload = {
        chat_id: '-4053066172',
        text
      };
      await axios.post(url, payload);
    },
    async handleSpecialCases () {
      if (this.selectedCard.type === 'segunda_mano') {
        await this.buyItem();
      } else if (this.selectedCard.id === 'especial') {
        this.sendMessage();
      }
    },
    async sendMessage () {
      try {
        await this.updateSpecial();
        this.mode = 'thanks';
      } catch {
        this.errorMessage = '¡Oh, oh! Ha ocurrido un error. Recarga la página e inténtalo de nuevo.';
        this.mode = 'error';
      }

      if (this.mode === 'thanks') {
        await this.notify();
      }
    },
    async buyItem () {
      try {
        const isItemAvailable = await this.isItemAvailable();
        if (isItemAvailable) {
          await this.updateData();
          this.$emit('buyItem', this.selectedCard);
          this.mode = 'thanks';
        } else {
          this.errorMessage = 'Lo sentimos, parece que alguien se ha adelantado y nos ha comprado este regalito. Recarga la página para ver los artículos actualizados.';
          this.mode = 'error';
        }
      } catch {
        this.errorMessage = '¡Oh, oh! Ha ocurrido un error. Recarga la página e inténtalo de nuevo.';
        this.mode = 'error';
      }

      if (this.mode === 'thanks') {
        await this.notify();
      }
    }
  }
};
</script>

<style scoped>
.v-btn {
  border: 1px solid var(--primary-color);
}
.img-product {
  margin: 20px 40px 0;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  max-width: 300px;
  /* overflow: hidden; */
}
.img-product .v-img__img {
  border-radius: 5px;
}
.info-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.info-address {
  opacity: 0.9;
  font-style: italic;
  text-align: center;
  font-family: auto;
}
.buy-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;;
}
.thanks {
  text-align: center;
}
.card-main {
  position: relative;
  padding: 10px 0 40px;
}
.background-img {
  content: '';
  opacity: 0.05;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background: url() no-repeat center center;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  background-size: contain;
}
.v-card-actions .v-btn:hover{
  box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 10px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12))
}
.description {
  white-space: pre-line;
}
a {
  color: #319672;
}
</style>
