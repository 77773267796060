<template>
  <v-dialog v-model="localModalVisible" @click:outside="closeModal" max-width="600">
      <v-card class="card" >
        <v-card-title class="modal-top d-flex justify-space-between align-center">
          <div>
            <span class="title">Mi primera foto</span>
          </div>

          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>

          </v-btn>
        </v-card-title>
        <v-img class="mx-1 my-1" :src="require(`../assets/eco.jpeg`)" ></v-img>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalVisible: Boolean
  },
  data () {
    return {
      localModalVisible: this.modalVisible
    };
  },
  watch: {
    modalVisible (newValue) {
      this.localModalVisible = newValue;
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:modalVisible', false);
      this.localModalVisible = false;
    }
  }
};
</script>

<style scoped>

</style>
